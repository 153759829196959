import Carousel from 'react-bootstrap/Carousel';


function ImageComponent({images}) {
    return (
        <Carousel fade> 
            {images.map((imageObj, i) => {
                return (
                    <Carousel.Item interval={4000} key={i}> 
                        <img 
                            className="d-block w-100" src={imageObj.url} alt="uploaded by rebecca"
                        /> 
                        {imageObj.caption ?
                        <Carousel.Caption> 
                            <p>{imageObj.caption}</p> 
                        </Carousel.Caption>
                        : null} 
                    </Carousel.Item>
                )
            })} 
        </Carousel> 
    );
}



export default ImageComponent;