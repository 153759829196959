import 'bootstrap/dist/css/bootstrap.min.css';

import moment from 'moment';
import axios from 'axios';

import {Container, Row, Col, Button} from 'react-bootstrap'
import ImageComponent from './ImageComponent';
import { useEffect, useState } from 'react';

let rowStyle={paddingTop: '0.5em'}

const getDay = async (date) => {
  let backendDate = date.format("YYYYMMDD");

  let texts = [];
  let images = [];

  const textsResponse = await axios.get(`https://4d0sslk2bi.execute-api.eu-north-1.amazonaws.com/prod/${backendDate}/texts`)

  textsResponse.data.Items.forEach(item => {
    let msg = item.message.S
    texts.push(msg)
  });

  const imagesResponse = await axios.get(`https://4d0sslk2bi.execute-api.eu-north-1.amazonaws.com/prod/${backendDate}/images`)

  imagesResponse.data.Items.forEach(item => {
    let obj = {};
    let id = item.id.S;
    if ("caption" in item) {
      obj.caption = item.caption.S
    }
    obj.url = `https://d1hcjj7fiq7iy2.cloudfront.net/${backendDate}/${id}`
    images.push(obj)
  })

  return {
    date: date,
    texts: texts,
    images: images
  };
}


function App() {
  let [days, setDays] = useState([]);

  const getAnotherDay = async () => {
    let date = days[days.length - 1].date.clone().subtract(1, 'days');
    let day = await getDay(date)
    setDays([...days, day])
  }

  useEffect(() => {
    let inner = async () => {
      let date = moment();

      let today = await getDay(date)

      setDays([today])
    };
    inner();
  }, []);
  
  return (
    <Container style={{padding: '1em'}}>
      <Row style={rowStyle}>
        <Col><h1><center>Becks' DCEP Program</center></h1></Col>
      </Row>
      {days.map((day, i) => {
        return (
          <SingleDay key={i} texts={day.texts} date={day.date} images={day.images} />
        )
      })}
      <Row style={rowStyle}>
        <center><Button variant="info" style={{maxWidth: '25em'}} onClick={getAnotherDay}>Load Another day</Button></center>
      </Row>
    </Container>
  );
}

function SingleDay({texts, date, images}) {

  return (
    <Container>
      <Row style={rowStyle}>
        <Col><h3><center>{date.format("Do MMM YY")}</center></h3></Col>
      </Row>
      <Row>
        {images.length > 0 ? <ImageComponent images={images} /> : <center><p>No Images uploaded for this day!</p></center>}
      </Row>
      <Row style={rowStyle}>
        <Col><h4><center>Texts</center></h4></Col>
        {texts.length > 0 ? texts.map((text, i) => <center key={i}><p>{text}</p></center>) : <center><p>No Texts for this day!</p></center>}
      </Row>
    </Container>
  )
}

export default App;
